@font-face {
  font-family: 'AvertaStd';
  src: url('woff2/AvertaStd-Regular.woff2') format('woff2'),
        url('woff/AvertaStd-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AvertaStd';
  src: url('woff2/AvertaStd-RegularItalic.woff2') format('woff2'),
      url('woff/AvertaStd-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'AvertaStd';
  src: url('woff2/AvertaStd-Semibold.woff2') format('woff2'),
      url('woff/AvertaStd-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'AvertaStd';
  src: url('woff2/AvertaStd-Bold.woff2') format('woff2'),
      url('woff/AvertaStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('woff2/AvertaStd-Light.woff2') format('woff2'),
      url('woff/AvertaStd-Light.woff') format('woff');
  font-weight: 100 200;
  font-style: normal;
}