.dashboardContent {
    background-color: #FAFBFB;
    padding-top: 24px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
    margin-bottom: 43px;
    border-radius: 24px;
    box-shadow: 0px 8px 16px 0px #181A360A;
    max-width: calc(100% - 32px);
    overflow: hidden;
}
@media (max-width: 959.95px) {
    .dashboardContent {
        max-width: 100%;
        padding: 0;
    }
}

.breadCrumb {
    margin: 18px 0;
}

.breadCrumb a,
.breadCrumb span {
    font-family: AvertaStd;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
}

.breadCrumb .crc-MuiTypography-root {
    color: #181A36;
    font-weight: 400;
}

.breadCrumb .crc-MuiBreadcrumbs-li span{
    color: #181A36;
    font-weight: 600;
}
