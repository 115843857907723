@import 'assets/fonts/AvertaStdFont/AvertaStdFont.css';

html,
body {
  font-family: 'Barlow', sans-serif;
  min-height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

a {
  color: #fc4c02;
  text-decoration: none;
}

/* TODO: remove once feedback component is done */
.feedback-panel {
  margin: 0;
}
